import {ADD_GENERAL_INFO, REMOVE_GENERAL_INFO} from '@constants/redux';

const generalInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_GENERAL_INFO:
            return {
                ...state,
                ...action.payload,
            };
        case REMOVE_GENERAL_INFO:
            return {};
        default:
            return state;
    }
};

export default generalInfoReducer

