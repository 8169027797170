import React from 'react';
import logo from 'assets/svg/logo.svg';

const Header = ({availableVouchers, hasHeaderTitle}) => {

  return (
    <nav className="bg-opa">
      <div className={`relative flex items-center justify-center ${hasHeaderTitle ? 'h-66' : 'h-48'}`}>
        <div className="flex flex-col ">
          <img src={logo} alt={'logo'} className={`mx-auto h-20 w-26 ${hasHeaderTitle ? 'pt-8' : ''}`}/>
          {hasHeaderTitle && (
            <>
              <div className="flex justify-center">
                <div className="border border-4 border-yellow-400 rounded-full w-20 h-20 sm:w-22 sm:h-22 bg-transparent justify-center items-center flex font-extrabold text-white text-3xl sm:text-4xl p-1">
                  {availableVouchers}
                </div>
                <div className="text-xl sm:text-4xl font-bold p-1 text-white mt-6 ml-4 sm:ml-6">kupona të mbetur</div>
              </div>
              <h1 className="font-bold text-white text-2xl sm:text-4xl text-center">Mirë se vini në OPA Quiz.</h1>
              <h2 className="text-center text-xl text-white pb-4 px-16">Rendit 1 nga 6 lokacione sipas rendit te hapjes per te fituar</h2>
            </>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Header;

