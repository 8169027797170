import React from 'react';

const Footer = ({hasFooterText}) => {

  return (
    <nav className="bg-secondary">
      <div className={`relative flex items-center justify-center ${hasFooterText ? 'h-44' : 'h-32'} `}>
        {hasFooterText && (<div className="text-3xl sm:text-4xl text-white font-bold text-center px-6">
          Për të fituar, rendit saktë 1-6 nga hapja më e vjetër <span className="text-opa">OPA</span> tek ajo më e reja.
        </div>) }
      </div>
    </nav>
  )
}

export default Footer;

