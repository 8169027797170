import {ADD_GENERAL_INFO, REMOVE_GENERAL_INFO} from '@constants/redux';

export const addGeneralInfo = (payload) => {
    return {
        type: ADD_GENERAL_INFO,
        payload,
    };
};

export const removeGeneralInfo = () => {
    return {
        type: REMOVE_GENERAL_INFO,
    };
};
