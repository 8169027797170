import React, {useEffect, useState} from "react";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import API from "@utils/plugins/API";
import Layout from "@hoc/layouts/Layout";
import orangeCircle from 'assets/svg/orange_circle.svg';
import greenCircle from 'assets/svg/green_circle.svg';
import {useDispatch} from "react-redux";
import {addGeneralInfo} from "@redux/general/Action";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import _ from "lodash";

const BranchesMap = ({availableVouchers}) => {
  const dispatch = useDispatch()
  const [branches, setBranches] = useState([])
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [hasHeaderTitle, setHasHeaderTitle] = useState(true);
  const [hasFooterText, setHasFooterText] = useState(false);
  let center = {lat: 41.3275, lng: 19.8187}

  const onClick = (branch) => {
    if (selectedBranches.includes(branch)) {
      return
    }
    setSelectedBranches([...selectedBranches, branch]);
    setHasHeaderTitle(false);
    setHasFooterText(true);
  }

  const {isLoaded} = useLoadScript({googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY});

  useEffect(() => {
    if (selectedBranches.length === 6) {
      dispatch(showSpinner())
      const branchIds = selectedBranches.map((item) => item.id)
      API.post('/branches/check-selections-order', {branchIds}).then((res) => {
        const voucherCode = _.get(res, 'data.data.voucher.code', '')
        const productName = _.get(res, 'data.data.voucher.product', '')
        dispatch(addGeneralInfo({step: 'success', code: voucherCode, product: productName}))
      })
        .catch(() => dispatch(addGeneralInfo({step: 'failed'})))
        .finally(() => dispatch(hideSpinner()))
    }
  }, [selectedBranches.length])

  useEffect(() => {
    API.get('/branches').then((response) => {
      setBranches(response.data.data)
    })
  }, [])

  return (
    <Layout hasHeaderTitle={hasHeaderTitle} availableVouchers={availableVouchers} hasFooterText={hasFooterText}>
      {isLoaded && (
        <GoogleMap mapContainerClassName="w-full flex-1"
                   options={{mapTypeControl: false, streetViewControl: false}}
                   center={center}
                   zoom={11.4}
        >
          {branches?.map((branch) => (
              <Marker key={branch.id} position={{lat:branch.latitude, lng:branch.longitude}}
                      onClick={() => onClick(branch)}
                      icon={selectedBranches.includes(branch) ? greenCircle : orangeCircle}
              />
            )
          )}
        </GoogleMap>
      )}
    </Layout>
  )
}

export default BranchesMap;