import React from "react";
import logo from "@assets/svg/logo.svg"
import greekStreetFood from "@assets/svg/greek_street_food.svg"
import sadSmiley from "@assets/svg/sad_smiley.svg"
import {FaFacebook, FaInstagram, FaTiktok} from "react-icons/fa";

const ErrorLayout = ({message = 'Më shumë fat herë tjetër'}) => {

  return (
    <div className="bg-opa h-screen flex flex-col items-center justify-center">
      <img src={logo} alt={'logo'} className="mx-auto h-26 w-26"/>
      <h1 className="text-white text-4xl mb-1 font-semibold">Hey na vjen keq.</h1>
      <h2 className="text-secondary text-xl font-bold">{message}</h2>
      <div className="flex py-2 mt-8 bg-secondary w-full items-center justify-center">
        <a href="https://www.facebook.com/OPA-Greek-Street-Food-103704844749314" target={"_blank"}><FaFacebook size={32} color={"white"} className="mx-2"/></a>
        <a href="https://www.instagram.com/opa.com.al/?igshid=YmMyMTA2M2Y%3D" target={"_blank"}><FaInstagram size={32} color={"white"} className="mx-2"/></a>
        <a href="https://www.tiktok.com/@opagreekstreetfood?_t=8XqWMGj5DuQ&_r=1" target={"_blank"}><FaTiktok size={32} color={"white"} className="mx-2"/></a>
      </div>
      <img src={sadSmiley} alt="smileyLogo" className="mt-12 mb-2"/>
      <img src={greekStreetFood} alt="streetFoodLogo" className="my-2"/>
    </div>
  )
}

export default ErrorLayout;