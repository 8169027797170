import {combineReducers} from 'redux';
import spinnerReducer from './spinner/Reducer';
import generalInfoReducer from "@redux/general/Reducer";

const Reducers = combineReducers({
    generalInfoReducer,
    spinnerReducer,
});

export default Reducers;
