import React from "react";
import greekStreetFood from "@assets/images/greek_street_food_yellow.png"
import celebrationSmiley from "@assets/svg/celebration_logo.svg"
import strippedLines from "@assets/svg/stripped_line.svg";
import {useSelector} from "react-redux";
import _ from "lodash";

const SuccessLayout = () => {
  const code = useSelector((state) => _.get(state, 'generalInfoReducer.code', ''))
  const product = useSelector((state) => _.get(state, 'generalInfoReducer.product', ''))

  return (
    <div className="bg-secondary h-screen flex flex-col items-center justify-between">
        <img src={strippedLines} alt="strippedLines"/>
        <img src={greekStreetFood} alt="streetFoodLogo" className="h-10 w-10"/>
        <div className="flex flex-col text-center px-6">
            <h1 className="text-4xl font-extrabold text-success">URIME</h1>
            <h1 className="text-4xl font-bold text-success">ju sapo fituat:</h1>
        </div>
        <img src={celebrationSmiley} alt="celebrationSmiley" className="h-16 w-16"/>
      <div className="py-1 mx-14">
        <h1 className="text-white text-center text-4xl font-extrabold">{product}</h1>
        <h2 className="text-2xl text-white font-extralight text-center border rounded-full p-1.5 mt-4">{code}</h2>
      </div>
        <h1 className="text-white text-center text-sm px-12">Bëje screenshot dhe sille kodin e mësipërm në dyqanin me te afert OPA</h1>
        <a href="https://www.opa.com.al/order" target={"_blank"} className="text-xl font-extrabold border-2 bg-white text-secondary rounded-full py-2 px-8">OSE POROSITE KËTU</a>
        <img src={strippedLines} alt="strippedLines"/>
    </div>
  )
}

export default SuccessLayout;