import React from 'react';
import { Switch, BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import HomePage from "@pages/HomePage";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Redirect from='*' to='/'/>
      </Switch>
    </Router>
  );
};

export default Routes;
