import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import Reducers from "./Reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";

const middleware = [];
const enhancers = [];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enableWhiteList = process.env.REACT_APP_NODE_ENV === 'production'

const persistConfig = {
  key: "root",
  storage,
};

if (enableWhiteList) {
  persistConfig.whitelist = ['generalInfoReducer'];
} else {
  persistConfig.blacklist = ['generalInfoReducer']
};

const persistedReducer = persistReducer(persistConfig, Reducers);

middleware.push(logger);
middleware.push(thunk);
enhancers.push(applyMiddleware(...middleware));

const store = createStore(persistedReducer, {}, composeEnhancers(...enhancers));

const persistor = persistStore(store);

export { store, persistor };
