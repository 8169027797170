import React, {useEffect, useState} from "react";
import BranchesMap from "@components/BranchesMap/BranchesMap";
import SuccessLayout from "@components/SuccessLayout/SuccessLayout";
import ErrorLayout from "@components/ErrorLayout/ErrorLayout";
import {FaCircleNotch} from "react-icons/fa";
import API from "@utils/plugins/API";
import {useSelector} from "react-redux";
import _ from 'lodash';
import  { v4 as uuidv4} from 'uuid';
import {showSuccess} from "@utils/helpers";

const Loading = () => (
  <div className={'bg-opa w-full h-screen flex justify-center items-center'}>
    <FaCircleNotch size={50} className={'animate-spin relative mb-2 text-white'} />
  </div>
);

const HomePage = () => {
  const step = useSelector((state) => _.get(state, 'generalInfoReducer.step', 'map'))
  const [availableVouchers, setAvailableVouchers] = useState(0);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let userUniqueIdentification = localStorage.getItem('userUniqueIdentification')
    if (!userUniqueIdentification) {
      userUniqueIdentification = uuidv4();
      localStorage.setItem('userUniqueIdentification', (userUniqueIdentification));
    }
    API.post('/devices/log', {deviceId: userUniqueIdentification})
        .then(() => {
        })
  }, [])

  useEffect(() => {
    API.get('vouchers/count')
      .then((res) => {
        setAvailableVouchers(res.data.data.count);
      })
      .finally(() => {
      setLoading(false)
    })
  }, []);

  if (loading) {
    return <Loading/>
  }

  if (availableVouchers === 0) {
    return <ErrorLayout message={"Nuk ka më kupona për këtë promocion"} />
  }

  return (
    <div>
      {step === 'map' && <BranchesMap availableVouchers={availableVouchers} />}
      {step === 'success' && <SuccessLayout />}
      {step === 'failed' && <ErrorLayout />}
    </div>
  );
}

export default HomePage;
