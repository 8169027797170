import React from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";

const Layout = ({children, availableVouchers, hasHeaderTitle, hasFooterText}) => {
  return (
    <div>
      <div className={"min-h-screen flex flex-row bg-gray-100"}>
        <div className={"flex-1 flex flex-col"}>
          <Header availableVouchers={availableVouchers} hasHeaderTitle={hasHeaderTitle}/>
          {children}
          <Footer hasFooterText={hasFooterText}/>
        </div>
      </div>
    </div>

  );
};

export default Layout;
