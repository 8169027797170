// AUTH
export const ADD_AUTH = 'ADD_AUTH';
export const REMOVE_AUTH = 'REMOVE_AUTH';

// ME
export const ADD_GENERAL_INFO = 'ADD_GENERAL_INFO';
export const REMOVE_GENERAL_INFO = 'REMOVE_GENERAL_INFO';

// SPINNER
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

